import React, { useState, useEffect, ChangeEvent } from 'react';
import Link from "next/link";
import SearchIcon from "./icons/SearchIcon";
import CheckIcon from '../Icons/CheckIcon';

interface Item {
    id: number;
    name: string;
    slug: string
}

interface SearchableDropdownProps {
    fetchUrl: string;
    currentWorkspace: {
        id: any;
    };
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({ fetchUrl, currentWorkspace }) => {
    const [items, setItems] = useState<Item[]>([]);
    const [filteredItems, setFilteredItems] = useState<Item[]>([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(fetchUrl);
            const data: Item[] = await response.json();

            setItems(data);
            setFilteredItems(data);
        };

        fetchData();
    }, [fetchUrl]);

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);
        const filtered = items.filter((item) =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredItems(filtered);
    };

    return (
        <div className="z-10 origin-top-right absolute w-56 rounded-md shadow-lg bg-white dark:bg-black focus:outline-none pb-2 overflow-y-auto max-h-[300px]">
            <div className="max-h-[175px] overflow-auto" role="none">
                <div className="border-subtle !p-4 w-full group flex px-4 py-3 text-xs leading-5 border-b">
                    <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-1 pointer-events-none">
                            <SearchIcon stroke="#9ca3af" />
                        </div>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearch}
                            placeholder="Search by organization"
                            className="w-full pr-4 text-xs text-textUui dark:text-white border border-gray-300 focus:outline-none focus:border-link-500 focus:ring-1 focus:ring-link-500 flex-auto placeholder:textUuiSlateBlue h-10 text-sm pl-8 rounded dark:bg-black"
                        />
                    </div>
                </div>
            </div>
            {
                filteredItems.map((workspace) => (
                    <div className="max-h-[175px] overflow-auto" role="none" key={workspace.slug || workspace.id}>
                        <Link
                            className="border-subtle !py-2 px-4 w-full group flex items-center text-xs leading-6 hover:bg-gray-100 dark:hover:bg-transparent"
                            href={`/${workspace.slug || workspace.id}`}
                        >
                            <span className="self-start text-gray-600 dark:text-white pl-2">
                                {workspace.name || workspace.slug || workspace.id}
                            </span>
                            {(workspace.id == currentWorkspace.id) && (<CheckIcon className="h-4 w-4 bg-[#0053f2] rounded-[100px] ml-auto"/>)}
                        </Link>
                    </div>
                ))
            }
        </div>
    );
};

export default SearchableDropdown;
