import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} style={style}>
      <path d="M10.0684 7.93064C10.6085 8.47076 11.4842 8.47077 12.0244 7.93064C12.5645 7.39052 12.5645 6.51481 12.0244 5.97468C11.4842 5.43456 10.6085 5.43456 10.0684 5.97468C9.52828 6.51481 9.52829 7.39052 10.0684 7.93064Z" stroke={stroke} stroke-miterlimit="10" stroke-linejoin="round"/>
      <path d="M1.50613 8.25424L3.77862 5.98176C4.52134 5.23904 5.72549 5.23904 6.46821 5.98176" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.62846 16.3766L11.9009 14.1041C12.6437 13.3614 12.6437 12.1572 11.9009 11.4145" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.3322 8.98193L9.43566 13.8784L4.0025 8.44525L8.89899 3.54877" stroke={stroke} stroke-miterlimit="10" stroke-linejoin="round"/>
      <path d="M14.3316 8.98203C16.0568 7.25684 16.3692 4.65427 15.2696 2.61087C13.2262 1.51126 10.6236 1.82368 8.89846 3.54887" stroke={stroke} stroke-miterlimit="10" stroke-linejoin="round"/>
      <path d="M3.7207 10.7875C1.99552 12.5127 1.24888 14.6811 1.75255 16.1285C3.20001 16.6322 5.36838 15.8856 7.09357 14.1604" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.4418 1.44165L15.2714 2.61199" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.40839 12.4752L4.23805 13.6456" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default function ContactSupportIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
