import React from "react";
import {useWorkspace} from "../../lib/context";
import SearchableDropdown from "./SearchableDropdown";
import {ChevronDown} from "lucide-react";
import {Dropdown} from "antd";

const WorkspaceDrawer =  () => {
    const workspace = useWorkspace();

    return (
        <div className="flex justify-between bg-white bg-opacity-10 gap-2.5 rounded-3xl py-1.5 px-4 cursor-pointer hover:bg-opacity-20 transition-all">
            <Dropdown
                dropdownRender={() => (
                    <SearchableDropdown fetchUrl={"/api/workspace"} currentWorkspace={workspace} />
                )}
                placement="bottomLeft"
                arrow
                trigger={["click"]}
            >
                <div className="flex  items-center">
                    <img className="mr-2"
                         src="https://uui.cience.com/assets/icons/switcher-62f7f1e764573bb52ab76d6e5e05c421d0233cff.svg"
                         alt="UUI Icon"/>
                    <span className="text-xs text-white">{workspace.name}</span>
                    <ChevronDown className="ml-2" size={16}/>
                </div>

            </Dropdown>
        </div>
    );
}

export default WorkspaceDrawer;
