import React from 'react';
import Link from 'next/link';
import { ChevronDown } from 'lucide-react';
import { useUser } from "../../lib/context";
import { Dropdown } from "antd";

import UserProfileNavDrawer from "../UuiNavigation/UserProfileNavDrawer";
import WorkspaceDrawer from "../UuiNavigation/WorkspaceDrawer";
import Graph8Logo from "../Icons/Graph8Logo";

interface User {
  name?: string;
  email?: string;
}

interface UuiNavBarProps {
  user: User;
}

function abbr(user: User) {
  return (
    (user?.name)
      ?.split(" ")
      .filter((part) => part?.length > 0)
      .map((part) => part?.[0])
      .join("")
      .toUpperCase()
  );
}

const UserProfileButton: React.FC<{}> = () => {
  const user = useUser();
  return (
    <Dropdown
      dropdownRender={() => (
        <UserProfileNavDrawer
          user={{
            email: user.email,
            name: user.name,
          }}
        />
      )}
      placement="bottomLeft"
      arrow
      trigger={["click"]}
    >
      <span>
        <div className="flex cursor-pointer items-center gap-4" style={{ color: "#fff" }}>
          <div
            className="flex h-8 w-8 items-center justify-center gap-4 rounded-full font-bold"
            style={{ letterSpacing: "-1.5px", background: "#FFFFFF1A" }}
          >
            {abbr(user)}
          </div>
          <div className="flex flex-col">
            <span className="hidden max-w-[250px] overflow-hidden sm:block">
              {user?.email ?? user?.name ?? "Nameless User"}
            </span>
            <span className="max-w-[200px] overflow-hidden text-ellipsis text-[#7396af]"> </span>
          </div>
          <ChevronDown size={18} />
        </div>
      </span>
    </Dropdown>
  );
};

const UuiNavBar: React.FC<UuiNavBarProps> = ({ user }) => {
  return (
    <nav className="fixed z-40 flex h-[60px] w-full items-center justify-between bg-[#0A0118] px-4 text-white sm:relative sm:p-4">
      <span className="z-50 flex items-center justify-center">
        <Link href="https://uui.cience.com">
          <div className="flex">
            <Graph8Logo className="max-h-[28px] max-w-[112px]" />
          </div>
        </Link>
      </span>

        <div className="z-10 flex items-center gap-10 self-center" data-testid="user-dropdown-trigger">

        <WorkspaceDrawer />

        <UserProfileButton />
      </div>
    </nav>
  );
};

export default UuiNavBar;
