import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} style={style}>
      <path d="M9.52631 0.526387L14 0.526386C16.7614 0.526386 19 2.76496 19 5.52639L19 14.4738C19 17.2352 16.7614 19.4738 14 19.4738L9.52632 19.4738" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.05251 6.21069L12.842 10.0002L9.05251 13.7896" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.3683 10L0.999927 10" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default function LiveChatIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
