import React from "react";

import type { IconProps } from "./types";

const ConnectionsIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0213 7L8.16411 11.0635L15.0213 15L21.8784 11.0635L15.0213 7Z"
      stroke={stroke}
      stroke-linejoin="round"
    />
    <path
      d="M21.836 11.1479V19.2326L15.0635 22.9998L8.12169 19.2326V11.1479"
      stroke={stroke}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M15.0636 14.9575V22.9998" stroke={stroke} />
  </svg>
);

export default ConnectionsIcon;
