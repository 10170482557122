import React from "react";

import type { IconProps } from "./types";

const GoEngageCampaignIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6591 10.864C11.4952 10.864 12.173 10.1862 12.173 9.35018C12.173 8.51412 11.4952 7.83636 10.6591 7.83636C9.82308 7.83636 9.14532 8.51412 9.14532 9.35018C9.14532 10.1862 9.82308 10.864 10.6591 10.864Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.3409 22.1636C20.177 22.1636 20.8547 21.4859 20.8547 20.6498C20.8547 19.8137 20.177 19.136 19.3409 19.136C18.5048 19.136 17.8271 19.8137 17.8271 20.6498C17.8271 21.4859 18.5048 22.1636 19.3409 22.1636Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.3409 16.6229C20.177 16.6229 20.8547 15.9451 20.8547 15.1091C20.8547 14.273 20.177 13.5953 19.3409 13.5953C18.5048 13.5953 17.8271 14.273 17.8271 15.1091C17.8271 15.9451 18.5048 16.6229 19.3409 16.6229Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.827 15.1092H10.6591"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6453 10.8364V15.8364C10.6453 18.5978 12.8839 20.8364 15.6453 20.8364H17.6453"
      stroke={stroke}
    />
  </svg>
);

export default GoEngageCampaignIcon;
