import React from 'react';
import { useRouter } from "next/router";
import { useJitsu } from "@jitsu/jitsu-react";
import { useUserSessionControls } from "../../lib/context";
import { Menu } from "antd";
import { userProfileMenuLinks } from "./NavItems";
import { useTheme } from "../../components/ThemeContext/ThemeContext";
import ChangeThemeIcon from "../../public/images/menu/ChangeThemeIcon";
import LogoutIcon from "../../public/images/menu/LogoutIcon";

const UserProfileNavDrawer = ({ user }) => {
  const router = useRouter();
  const { analytics } = useJitsu();
  const sessionControl = useUserSessionControls();
  const { darkMode, toggleDarkMode } = useTheme();

  console.log("Current dark mode:", darkMode); // Added log

  const handleMenuItemClick = (href, onClick) => {
    if (href) {
      window.open(href, '_blank');
    } else if (onClick) {
      onClick();
    }
  };

  const handleThemeToggle = () => {
    toggleDarkMode();
  };

  const renderMenuItem = (item: typeof userProfileMenuLinks[0], index: number) => {
    const IconComponent = item.icon;
    return (
      <React.Fragment key={item.id}>
        <Menu.Item
          onClick={() => handleMenuItemClick(item.href, item.onClick)}
          className="border-subtle !p-4 w-full group flex items-center px-4 py-3 text-xs leading-5 text-gray-700 dark:text-gray-200"
        >
          <IconComponent width={20} height={20} className="inline" />
          <span className="text-base self-start text-newGray-900 dark:text-white pl-2">
            {item.title}
          </span>
        </Menu.Item>
        {item.addDivider && <Menu.Divider />}
      </React.Fragment>
    );
  };

  return (
    <div className="z-10 origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 focus:outline-none">
      <Menu className="menu">
        {userProfileMenuLinks.map((item, index) => renderMenuItem(item, index))}
        <Menu.Item
          onClick={handleThemeToggle}
          className="border-subtle !p-4 w-full group flex items-center px-4 py-3 text-xs leading-5"
        >
					<ChangeThemeIcon width={20} height={20} className="inline" />
          <span className="text-base self-start text-newGray-900 dark:text-white pl-2">
            Change theme
          </span>
        </Menu.Item>
        <Menu.Item
          onClick={async () => {
            await sessionControl.logout();
            analytics.reset();
            router.push("/", undefined, { shallow: true });
          }}
          className="border-subtle !p-4 w-full group flex items-center px-4 py-3 text-xs leading-5"
        >
          <LogoutIcon width={20} height={20} className="inline" />
          <span className="text-base self-start text-newGray-900 dark:text-white pl-2">
            Logout
          </span>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default UserProfileNavDrawer;
