import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} style={style}>
      <path d="M17.452 9.10777L19 7.55973L17.2457 5.80461C16.8406 6.20624 16.2928 6.43102 15.7223 6.42978C15.1518 6.42853 14.605 6.20134 14.2016 5.79794C13.7982 5.39454 13.571 4.84776 13.5698 4.27727C13.5685 3.70677 13.7933 3.15901 14.195 2.75384L12.4407 0.999573L1 12.4411L2.75427 14.1954C3.15943 13.7938 3.7072 13.569 4.27769 13.5702C4.84819 13.5715 5.39497 13.7987 5.79837 14.2021C6.20177 14.6055 6.42895 15.1522 6.4302 15.7227C6.43145 16.2932 6.20667 16.841 5.80503 17.2462L7.5593 19.0004L10.0617 16.498" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.06604 9.37555L7.35324 12.6627" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.0762 15.0207C18.6596 14.2691 18.9995 13.376 18.9995 12.4165C18.9995 9.75272 16.3906 7.5929 13.172 7.5929C9.95338 7.5929 7.34485 9.75272 7.34485 12.4165C7.34485 15.0802 9.95381 17.2401 13.172 17.2401C14.1519 17.2437 15.1208 17.0337 16.0113 16.6248L18.6798 17.4014L18.0762 15.0207Z" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.4403 10.8372H15.9059" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M10.4403 12.5941H15.9059" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M10.4403 14.3508H15.9059" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round"/>
    </svg>
  );
}

export default function HelpCenterIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
