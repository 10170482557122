import React from "react";

import type { IconProps } from "./types";

const BotTasksIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4509 17.5C12.7271 17.5 12.9509 17.2761 12.9509 17C12.9509 16.7239 12.7271 16.5 12.4509 16.5C12.1748 16.5 11.9509 16.7239 11.9509 17C11.9509 17.2761 12.1748 17.5 12.4509 17.5Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M17.4509 17.5C17.7271 17.5 17.9509 17.2761 17.9509 17C17.9509 16.7239 17.7271 16.5 17.4509 16.5C17.1748 16.5 16.9509 16.7239 16.9509 17C16.9509 17.2761 17.1748 17.5 17.4509 17.5Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M15.0585 9.34969C15.7073 9.34969 16.2333 8.8237 16.2333 8.17485C16.2333 7.526 15.7073 7 15.0585 7C14.4096 7 13.8836 7.526 13.8836 8.17485C13.8836 8.8237 14.4096 9.34969 15.0585 9.34969Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M20.7715 19.1618C19.8524 21.4134 17.6408 23 15.0587 23C12.5092 23 10.3211 21.4533 9.38144 19.247"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M9.3118 14.5853C10.21 12.2897 12.4441 10.6637 15.0579 10.6637C17.7061 10.6637 19.9646 12.3327 20.8387 14.6763"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path d="M15.0585 9.34921V10.6634" stroke={stroke} stroke-miterlimit="10" stroke-linejoin="round" />
    <path
      d="M19.4057 19.5408H10.5943C9.13447 19.5408 7.95093 18.3572 7.95093 16.8974C7.95093 15.4375 9.13447 14.254 10.5943 14.254H19.4057C20.8655 14.254 22.0491 15.4375 22.0491 16.8974C22.0491 18.3574 20.8657 19.5408 19.4057 19.5408Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
  </svg>
);

export default BotTasksIcon;
