import React from "react";

import type { IconProps } from "./types";

const SettingsIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.3261 7.00159H13.6771L13.2644 8.95C12.81 9.08117 12.3717 9.26353 11.9558 9.49389L10.2825 8.40611L8.40772 10.2809L9.49551 11.9542C9.26515 12.3669 9.08278 12.8052 8.95161 13.2627L7 13.6755V16.3245L8.94841 16.7372C9.07958 17.1915 9.26195 17.6299 9.4923 18.0458L8.40452 19.719L10.2793 21.5939L11.9526 20.5061C12.3653 20.7364 12.8036 20.9188 13.2611 21.05L13.6771 22.9984H16.3261L16.7389 21.0468C17.1932 20.9188 17.6315 20.7364 18.0474 20.5061L19.7207 21.5939L21.5955 19.719L20.5077 18.0458C20.738 17.6331 20.9204 17.1947 21.0516 16.7372L23 16.3213V13.6723L21.0484 13.2595C20.9204 12.8052 20.738 12.3669 20.5077 11.951L21.5955 10.2777L19.7207 8.40291L18.0474 9.49069C17.6347 9.26033 17.1932 9.07797 16.7389 8.9468L16.3261 7.00159ZM18.4505 14.9968C18.4505 16.9004 16.9084 18.4457 15.0016 18.4457C13.098 18.4457 11.5527 16.9036 11.5527 14.9968C11.5527 13.0932 13.0948 11.5479 15.0016 11.5479C16.9052 11.5479 18.4505 13.0932 18.4505 14.9968Z"
      stroke={stroke}
      stroke-linecap="round"
    />
  </svg>
);

export default SettingsIcon;
