import React from "react";

import type { IconProps } from "./types";

const SearchIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4171 19.6942C16.9006 19.6942 19.7246 16.8703 19.7246 13.3868C19.7246 9.90323 16.9006 7.07928 13.4171 7.07928C9.93357 7.07928 7.10962 9.90323 7.10962 13.3868C7.10962 16.8703 9.93357 19.6942 13.4171 19.6942Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.8901 22.9207L17.8901 17.9207"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SearchIcon;
