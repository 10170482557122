import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg 
      width="100%" 
      height="100%" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <g clip-path="url(#clip0_148_457)">
        <path d="M12 4.75H9.75C6.99 4.75 4.75 6.99 4.75 9.75V14.25C4.75 17.01 6.99 19.25 9.75 19.25H14.25C17.01 19.25 19.25 17.01 19.25 14.25V9.75C19.25 6.99 17.01 4.75 14.25 4.75H12Z" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.96997 16.33C10.45 16.33 11.66 15.13 11.66 13.64V10.36C11.66 8.87998 12.86 7.66998 14.35 7.66998H15.04" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.56006 11.54H14.4401" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_148_457">
          <rect width="15.5" height="15.5" fill="white" transform="translate(4.25 4.25)"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default function FunctionsIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
