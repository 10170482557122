import React from "react";

import type { IconProps } from "./types";

const AppointmentsIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="7" y="9" width="16" height="14" rx="3" stroke={stroke} />
    <path
      d="M12 17.75L13.8799 19.5L17.6 16"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 13.2266H22.9192"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.5879 7V10.7076"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.614 7V10.7076"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default AppointmentsIcon;
