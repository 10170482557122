import React from "react";

import type { IconProps } from "./types";

const ListsIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.68613 17.7445C8.20262 17.7445 7 16.5419 7 15.0584L7 9.68613C7 8.20262 8.20262 7 9.68613 7L15.0584 7C16.5419 7 17.7445 8.20262 17.7445 9.68613"
      stroke={stroke}
    />
    <path
      d="M20.314 12.2556C21.7975 12.2556 23.0001 13.4582 23.0001 14.9417V20.314C23.0001 21.7975 21.7975 23.0001 20.314 23.0001H14.9417C13.4582 23.0001 12.2556 21.7975 12.2556 20.314L12.2556 14.9417C12.2556 13.4582 13.4582 12.2556 14.9417 12.2556L20.314 12.2556Z"
      stroke={stroke}
    />
    <path
      d="M12.3724 20.4309C10.8889 20.4309 9.68628 19.2283 9.68628 17.7448L9.68628 12.3725C9.68628 10.889 10.8889 9.68637 12.3724 9.68637L17.7447 9.68637C19.2282 9.68637 20.4308 10.889 20.4308 12.3725"
      stroke={stroke}
    />
    <path
      d="M16.1333 17.8166L17.2971 18.8999L19.6 16.7332"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ListsIcon;
