import React from "react";

import type { IconProps } from "./types";

const ArticlesIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8484 12.6H14.8484" stroke={stroke} stroke-linecap="round" />
    <path d="M10.8484 15.2891H18.8484" stroke={stroke} stroke-linecap="round" />
    <path d="M10.8484 17.9781H18.8484" stroke={stroke} stroke-linecap="round" />
    <rect x="7" y="7" width="16" height="16" rx="5" stroke={stroke} />
  </svg>
);

export default ArticlesIcon;
