import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg 
      width="100%" 
      height="100%" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path d="M16.3316 10.3909C16.3061 10.3458 16.269 10.3083 16.2242 10.2822C16.1794 10.2561 16.1285 10.2424 16.0767 10.2424H12.2383L12.8818 5.32545C12.8887 5.25964 12.8733 5.19343 12.838 5.13747C12.8026 5.0815 12.7495 5.03905 12.6872 5.01694C12.6248 4.99483 12.5568 4.99435 12.4942 5.01558C12.4315 5.03681 12.3778 5.07851 12.3417 5.13397L7.67377 13.312C7.64648 13.3564 7.63152 13.4073 7.63043 13.4594C7.62935 13.5115 7.64219 13.5629 7.66762 13.6084C7.69305 13.6539 7.73015 13.6918 7.7751 13.7182C7.82005 13.7446 7.87123 13.7585 7.92334 13.7585H11.7044L11.1944 18.6828C11.1894 18.7484 11.2065 18.8137 11.2429 18.8685C11.2793 18.9232 11.333 18.9643 11.3954 18.985C11.4579 19.0057 11.5254 19.005 11.5874 18.9829C11.6493 18.9608 11.7021 18.9186 11.7373 18.863L16.3281 10.6859C16.3547 10.6414 16.3691 10.5907 16.3697 10.5389C16.3703 10.4871 16.3572 10.436 16.3316 10.3909Z" stroke={stroke}/>
    </svg>
  );
}

export default function FlashIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
