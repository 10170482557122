import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";
  
  return (
    <svg width="100%" 
      height="100%" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
    <rect x="1" y="17.0966" width="10.6667" height="16" rx="2" transform="rotate(-90 1 17.0966)" stroke={stroke}/>
      <path d="M4.84839 10.1534H8.84839" stroke={stroke} stroke-linecap="round"/>
      <path d="M4.84839 12.8424H12.8484" stroke={stroke} stroke-linecap="round"/>
      <path d="M15.5454 6.23672L15.5454 5.57005C15.5454 4.46548 14.65 3.57005 13.5454 3.57005L4.21208 3.57005C3.10751 3.57005 2.21208 4.46548 2.21208 5.57005L2.21208 6.23672" stroke={stroke}/>
      <path d="M14.3334 3.57005L14.3334 2.90338C14.3334 1.79881 13.4379 0.903381 12.3334 0.903381L5.66671 0.903381C4.56214 0.903381 3.66671 1.79881 3.66671 2.90338L3.66671 3.57005" stroke={stroke}/>
    </svg>
  );
}

export default function LogsIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
