import React from "react";

import type { IconProps } from "./types";

const ContactsIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 17 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inset-0 mx-auto mt-2">
    <path
      stroke={stroke}
      d="M6.41763 0.50003C5.72923 0.50003 5.05629 0.704132 4.4839 1.08659C3.91152 1.46904 3.46542 2.01265 3.20198 2.64865C2.93854 3.28465 2.86959 3.98451 3.00389 4.65968C3.13819 5.33485 3.46971 5.95504 3.95649 6.44182C4.44326 6.92859 5.06345 7.26008 5.73863 7.39438C6.4138 7.52868 7.11361 7.45977 7.74961 7.19633C8.38561 6.93289 8.92922 6.48674 9.31167 5.91436C9.69413 5.34197 9.89828 4.66904 9.89828 3.98064C9.90018 3.52302 9.81142 3.06956 9.63718 2.64641C9.46294 2.22327 9.20665 1.83883 8.88307 1.51524C8.55948 1.19166 8.175 0.935327 7.75185 0.761083C7.3287 0.586839 6.87524 0.498128 6.41763 0.50003V0.50003Z"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      stroke={stroke}
      d="M11.3676 14.5002C11.4399 14.5007 11.5115 14.4859 11.5777 14.4569C11.6439 14.4279 11.7033 14.3852 11.7519 14.3317C11.8006 14.2782 11.8374 14.215 11.8599 14.1464C11.8825 14.0777 11.8904 14.005 11.883 13.933C11.7494 12.583 11.1185 11.3309 10.1131 10.4201C9.10766 9.50929 7.79949 9.00488 6.44286 9.00488C5.08622 9.00488 3.77805 9.50929 2.77262 10.4201C1.76719 11.3309 1.13634 12.583 1.00268 13.933C0.99531 14.005 1.00317 14.0777 1.02576 14.1464C1.04835 14.215 1.08515 14.2782 1.13378 14.3317C1.18241 14.3852 1.24175 14.4279 1.30796 14.4569C1.37418 14.4859 1.44578 14.5007 1.51808 14.5002H11.3676Z"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      stroke={stroke}
      d="M11.7549 13.1599H17.7364C17.8101 13.1573 17.8824 13.1397 17.949 13.108C18.0155 13.0762 18.0748 13.0311 18.1231 12.9755C18.1715 12.9199 18.2078 12.8549 18.23 12.7846C18.2521 12.7143 18.2596 12.6402 18.2518 12.5669C18.1343 11.676 17.7533 10.8406 17.1576 10.1678C16.5619 9.4951 15.7787 9.01576 14.9086 8.79132C14.0385 8.56688 13.1212 8.60759 12.2744 8.90824C11.4276 9.2089 10.69 9.75576 10.1562 10.4786"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      stroke={stroke}
      d="M13.9972 2.09864C13.4823 2.09864 12.9789 2.25134 12.5507 2.53743C12.1225 2.82352 11.7888 3.23019 11.5917 3.70594C11.3947 4.18169 11.3431 4.70516 11.4436 5.21022C11.544 5.71528 11.792 6.17922 12.1561 6.54335C12.5203 6.90747 12.9842 7.15545 13.4893 7.25591C13.9943 7.35637 14.5178 7.30481 14.9936 7.10775C15.4694 6.91068 15.876 6.57695 16.1621 6.14878C16.4482 5.72062 16.6009 5.21724 16.6009 4.70229C16.6018 4.36011 16.5351 4.02112 16.4046 3.7048C16.2741 3.38848 16.0823 3.10108 15.8404 2.85912C15.5984 2.61716 15.311 2.42545 14.9947 2.29494C14.6784 2.16443 14.3394 2.09769 13.9972 2.09864V2.09864Z"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ContactsIcon;
