import React from "react";

import type { IconProps } from "./types";

const AiConversationsIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.7283 18.4627C22.5448 17.4506 22.9932 16.1911 23 14.8907C23 11.2302 19.4279 8.27209 15 8.27209C10.5944 8.27209 7 11.2312 7 14.8907C7 18.5502 10.5721 21.5092 15 21.5092C16.3477 21.5169 17.6804 21.2249 18.9014 20.6543L22.5619 21.7278L21.7283 18.4627Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M17.1445 12.477V16.961" stroke={stroke} stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M15.7645 16.961L13.695 12.477L11.6255 16.961"
      stroke={stroke}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M12.3154 15.581H15.0748" stroke={stroke} stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export default AiConversationsIcon;
