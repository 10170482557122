import React from "react";

import type { IconProps } from "./types";

const HomeIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke={stroke}>
    <path
      d="M7.52881 21V14.769C7.52881 14.2007 7.77058 13.6593 8.19372 13.2799L13.8272 8.22922C14.602 7.53454 15.7801 7.55013 16.5363 8.26507L21.845 13.2842C22.2446 13.662 22.471 14.1876 22.471 14.7375V21C22.471 22.1046 21.5755 23 20.471 23H9.52881C8.42424 23 7.52881 22.1046 7.52881 21Z"
      stroke={stroke}
    />
    <path d="M11.5288 19H18.386" stroke={stroke} stroke-linecap="round" />
  </svg>
);

export default HomeIcon;
``;
