import React from "react";

import type { IconProps } from "./types";

const IntentsIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0001 9.4001C13.8925 9.4001 12.8098 9.72848 11.8889 10.3438C10.968 10.9592 10.2503 11.8338 9.82645 12.857C9.4026 13.8803 9.29166 15.0063 9.50774 16.0926C9.72382 17.1789 10.2572 18.1767 11.0404 18.9599C11.8235 19.743 12.8214 20.2764 13.9076 20.4924C14.9939 20.7085 16.1199 20.5976 17.1431 20.1738C18.1664 19.7499 19.041 19.0321 19.6563 18.1112C20.2716 17.1903 20.6001 16.1076 20.6001 15.0001C20.6032 14.2638 20.4604 13.5342 20.18 12.8534C19.8997 12.1726 19.4873 11.5541 18.9667 11.0335C18.4461 10.5129 17.8275 10.1005 17.1467 9.82011C16.4659 9.53977 15.7363 9.39704 15.0001 9.4001V9.4001Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M18 15.0001L23 15.0001" stroke={stroke} stroke-linecap="round" />
    <path d="M7 15.0001L12 15.0001" stroke={stroke} stroke-linecap="round" />
    <path d="M15 18L15 23" stroke={stroke} stroke-linecap="round" />
    <path d="M15 7L15 12" stroke={stroke} stroke-linecap="round" />
  </svg>
);

export default IntentsIcon;
