import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} style={style}>
      <path d="M1 8.73071C1 7.62615 1.89543 6.73071 3 6.73071H5.15385V12.8846H3C1.89543 12.8846 1 11.9891 1 10.8846V8.73071Z" stroke={stroke} stroke-linejoin="round"/>
      <path d="M19 10.8845C19 11.9891 18.1046 12.8845 17 12.8845L14.8462 12.8845L14.8462 6.73068L17 6.73068C18.1046 6.73068 19 7.62611 19 8.73068L19 10.8845Z" stroke={stroke} stroke-linejoin="round"/>
      <path d="M16.75 6.5769V6.5769C16.75 3.2632 14.0637 0.576904 10.75 0.576904H9.25C5.93629 0.576904 3.25 3.2632 3.25 6.5769V6.5769" stroke={stroke} stroke-linejoin="round"/>
      <path d="M11.125 16.5769L13.75 16.5769C15.4069 16.5769 16.75 15.2338 16.75 13.5769L16.75 12.5769" stroke={stroke} stroke-linejoin="round"/>
      <ellipse cx="9.65394" cy="16.5" rx="1.03846" ry="0.923077" stroke={stroke} stroke-linejoin="round"/>
    </svg>
  );
}

export default function ContactSupportIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
