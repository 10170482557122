export default async function ChatSnippetTemplate(encryptedJweData: string) {
  const data = encryptedJweData || "YOUR_ENCRYPTED_JWE_DATA";
  const app_id = process.env.NEXT_PUBLIC_GO_CHAT_APP_ID || "NNp2pr3bmtyx65oeEfWK2kMV";
  const app_host = process.env.NEXT_PUBLIC_GO_CHAT_APP_HOST || "uui.graph8.com";

  const Snippet = `
  (function(d,t) {
    var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
    g.src="https://${app_host}/embed.js"
    s.parentNode.insertBefore(g,s);
    g.onload=function(){
      new window.ChaskiqMessengerEncrypted({
        domain: 'https://${app_host}',
        ws:  'wss://${app_host}/cable',
        app_id: "${app_id}",
        data: "${data}",
        lang: "USER_LANG_OR_DEFAULTS_TO_BROWSER_LANG"
      })
    }
  })(document,"script");
`;

  if (document) {
      const script = document.createElement("script");
      script.setAttribute("id", "goChat");
      script.innerHTML = Snippet;
      document.head.appendChild(script);
  }
}
