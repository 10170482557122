import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg 
      width="100%" 
      height="100%" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
      >
      <path d="M7.04209 7.45797C7.99698 7.45797 8.77108 6.68387 8.77108 5.72898C8.77108 4.77409 7.99698 4 7.04209 4C6.0872 4 5.31311 4.77409 5.31311 5.72898C5.31311 6.68387 6.0872 7.45797 7.04209 7.45797Z" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.9577 19.9999C17.9126 19.9999 18.6867 19.2258 18.6867 18.2709C18.6867 17.316 17.9126 16.5419 16.9577 16.5419C16.0029 16.5419 15.2288 17.316 15.2288 18.2709C15.2288 19.2258 16.0029 19.9999 16.9577 19.9999Z" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.9577 13.6718C17.9126 13.6718 18.6867 12.8977 18.6867 11.9428C18.6867 10.9879 17.9126 10.2138 16.9577 10.2138C16.0029 10.2138 15.2288 10.9879 15.2288 11.9428C15.2288 12.8977 16.0029 13.6718 16.9577 13.6718Z" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.2288 11.9432H7.04211" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.2288 18.2711H10.5001C8.59042 18.2711 7.04211 16.7228 7.04211 14.8132V7.37075" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default function ConnectionsIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
