import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} style={style}>
      <path d="M4.10526 3.5H3C1.89543 3.5 1 4.39543 1 5.5V6.5C1 7.60457 1.89543 8.5 3 8.5H9.5C10.0523 8.5 10.5 8.94772 10.5 9.5V10.9649" stroke={stroke}/>
      <rect x="4" y="1" width="12" height="5" rx="2.5" stroke={stroke}/>
      <rect width="6" height="3" rx="1.5" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 12 17)" stroke={stroke}/>
    </svg>
  );
}

export default function ChangeThemeIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
