import React from "react";

import type { IconProps } from "./types";

const AiTemplatesIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg
    className="ml-2"
    width={size}
    height={size}
    viewBox="0 0 26 18"
    fill="none"
    stroke={stroke}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.4237 5.07275H1.57947C1.50351 5.07281 1.42827 5.06004 1.35809 5.03514C1.28791 5.01024 1.22417 4.97372 1.17049 4.92767C1.11681 4.88162 1.07423 4.82693 1.04524 4.76676C1.01626 4.70659 1.00144 4.64211 1.00159 4.57702V1.56849C1.00144 1.5034 1.01626 1.43892 1.04524 1.37875C1.07423 1.31858 1.11681 1.26389 1.17049 1.21784C1.22417 1.17179 1.28791 1.13527 1.35809 1.11037C1.42827 1.08547 1.50351 1.07269 1.57947 1.07275H7.4237C7.49966 1.07269 7.5749 1.08547 7.64508 1.11037C7.71526 1.13527 7.779 1.17179 7.83268 1.21784C7.88637 1.26389 7.92891 1.31858 7.9579 1.37875C7.98688 1.43892 8.00173 1.5034 8.00159 1.56849V4.57749C8.00166 4.64254 7.98678 4.70697 7.95776 4.76709C7.92875 4.8272 7.88616 4.88182 7.83248 4.92783C7.77881 4.97383 7.71509 5.01031 7.64495 5.03518C7.5748 5.06005 7.49961 5.07281 7.4237 5.07275Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M11.5781 12.9274H17.4221C17.498 12.9273 17.5733 12.9401 17.6434 12.965C17.7136 12.9899 17.7774 13.0264 17.8311 13.0725C17.8848 13.1185 17.9273 13.1732 17.9563 13.2334C17.9853 13.2935 18.0001 13.358 18 13.4231V16.4315C18.0001 16.4966 17.9853 16.5611 17.9563 16.6213C17.9273 16.6814 17.8848 16.7361 17.8311 16.7822C17.7774 16.8282 17.7136 16.8647 17.6434 16.8896C17.5733 16.9145 17.498 16.9273 17.4221 16.9272H11.5781C11.5021 16.9273 11.4269 16.9145 11.3567 16.8896C11.2865 16.8647 11.2228 16.8282 11.1691 16.7822C11.1154 16.7361 11.0729 16.6814 11.0439 16.6213C11.0149 16.5611 11 16.4966 11.0002 16.4315V13.4231C10.9982 13.3576 11.0118 13.2924 11.0402 13.2316C11.0685 13.1707 11.111 13.1154 11.1651 13.069C11.2191 13.0227 11.2836 12.9862 11.3546 12.9619C11.4256 12.9375 11.5016 12.9258 11.5781 12.9274V12.9274Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M1.55812 7.9274H7.42167C7.49766 7.92726 7.57292 7.94156 7.64315 7.96947C7.71338 7.99739 7.77719 8.03837 7.83092 8.09007C7.88466 8.14176 7.92727 8.20315 7.95628 8.27073C7.9853 8.3383 8.00014 8.41072 8 8.48383V16.3708C8.00014 16.4439 7.9853 16.5164 7.95628 16.5839C7.92727 16.6515 7.88466 16.7129 7.83092 16.7646C7.77719 16.8163 7.71338 16.8573 7.64315 16.8852C7.57292 16.9131 7.49766 16.9274 7.42167 16.9272H1.57882C1.50288 16.9273 1.42768 16.913 1.35751 16.885C1.28734 16.8571 1.22361 16.8161 1.16993 16.7644C1.11626 16.7127 1.07369 16.6513 1.04471 16.5838C1.01573 16.5163 1.00088 16.4439 1.00102 16.3708V8.48383C0.996397 8.41133 1.00749 8.33869 1.03356 8.27053C1.05964 8.20237 1.10016 8.14017 1.15253 8.08786C1.20491 8.03555 1.26799 7.99428 1.33781 7.96665C1.40763 7.93903 1.48265 7.92566 1.55812 7.9274Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M11.5581 1.0729H17.4217C17.4977 1.07277 17.5729 1.08707 17.6431 1.11498C17.7134 1.14289 17.7772 1.18388 17.8309 1.23557C17.8847 1.28727 17.9273 1.34866 17.9563 1.41623C17.9853 1.4838 18.0001 1.55622 18 1.62933V9.51634C18.0001 9.58945 17.9853 9.66187 17.9563 9.72944C17.9273 9.79701 17.8847 9.8584 17.8309 9.9101C17.7772 9.9618 17.7134 10.0028 17.6431 10.0307C17.5729 10.0586 17.4977 10.0729 17.4217 10.0728H11.5788C11.5029 10.0728 11.4277 10.0585 11.3575 10.0305C11.2873 10.0026 11.2236 9.9616 11.1699 9.90991C11.1163 9.85822 11.0737 9.79685 11.0447 9.72931C11.0157 9.66177 11.0009 9.5894 11.001 9.51634V1.62933C10.9964 1.55684 11.0075 1.4842 11.0336 1.41604C11.0596 1.34788 11.1002 1.28568 11.1525 1.23336C11.2049 1.18105 11.268 1.13978 11.3378 1.11216C11.4076 1.08454 11.4827 1.07117 11.5581 1.0729Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
);

export default AiTemplatesIcon;
