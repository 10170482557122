import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  return (
    <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 431 108"
    enableBackground="new 0 0 111 28"
    width="100%"
    height="100%"
    xmlSpace="preserve"
    className={className}
    style={style}
    >
    <defs>
      <style>
        {`
          .st0 { fill:url(#SVGID_1_); }
          .st1 { fill:url(#SVGID_00000078047094865326621340000013353032733648527748_); }
          .st2 { fill:url(#SVGID_00000156563856393748941530000010743249085513151879_); }
          .st3 { fill:url(#SVGID_00000049910708061365355850000008920163210330572688_); }
          .st4 { fill:url(#SVGID_00000112601745668638428430000015551025773515175582_); }
          .st5 { fill:url(#SVGID_00000026123462045251706220000000013356781384955014_); }
          .st6 { fill:url(#SVGID_00000125600688324287604450000009528283135999682694_); }
          .st7 { fill: #EB6DD3; }
          .st8 { fill:url(#SVGID_00000058586429427104881390000003428873476841128373_); }
          .st9 { fill:url(#SVGID_00000181050111591031047350000003117179548769251717_); }
          .st10 { fill: #FFFFFF; }
        `}
      </style>
    </defs>
    <g>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="-5.798"
        y1="23.5543"
        x2="259.8031"
        y2="23.5543"
        gradientTransform="matrix(1 0 0 -1 0 109.1931)">
        <stop offset="0" style={{ stopColor: "#4EC9E8" }} />
        <stop offset="0.53" style={{ stopColor: "#B979FF" }} />
        <stop offset="1" style={{ stopColor: "#EB6DD3" }} />
      </linearGradient>
      <path
        className="st0"
        d="M37.5,75.7c0,7.7-6.4,14-14.4,14c-6.7,0-12.5-4.5-14-10.8l-8.6,1.4l-0.2,0c2.2,10.4,11.7,18,22.7,18
  c12.8,0,23.2-10.1,23.2-22.5V73l-8.8,1.4V75.7L37.5,75.7z"
      />

      <linearGradient
        id="SVGID_00000099639073896783082610000005115890445520646788_"
        gradientUnits="userSpaceOnUse"
        x1="4.719192e-05"
        y1="57.7192"
        x2="259.8031"
        y2="57.7192"
        gradientTransform="matrix(1 0 0 -1 0 109.1931)">
        <stop offset="0" style={{ stopColor: "#4EC9E8" }} />
        <stop offset="0.53" style={{ stopColor: "#B979FF" }} />
        <stop offset="1" style={{ stopColor: "#EB6DD3" }} />
      </linearGradient>
      <path
        style={{ fill: "url(#SVGID_00000099639073896783082610000005115890445520646788_)" }}
        d="M55.7,48.1v26.5h8.9V48.1
  c0-8.3,4.4-11.6,11-11.6c2.4,0,3.6,0.4,3.6,0.4l2.7-7.7c-1.9-0.5-4.1-0.7-6.3-0.7C63.7,28.4,55.7,34.8,55.7,48.1L55.7,48.1z"
      />

      <linearGradient
        id="SVGID_00000124160485066640746480000006307107634089198491_"
        gradientUnits="userSpaceOnUse"
        x1="-5.7982"
        y1="57.127"
        x2="259.8031"
        y2="57.127"
        gradientTransform="matrix(1 0 0 -1 0 109.1931)">
        <stop offset="0" style={{ stopColor: "#4EC9E8" }} />
        <stop offset="0.53" style={{ stopColor: "#B979FF" }} />
        <stop offset="1" style={{ stopColor: "#EB6DD3" }} />
      </linearGradient>
      <path
        style={{ fill: "url(#SVGID_00000124160485066640746480000006307107634089198491_)" }}
        d="M108.8,28.4c-14.7,0-25,9.9-25,24
  c0,14.8,11.4,23.3,23.5,23.3c8.1,0,14.9-3.7,18-11v9.8h8.1V52.4C133.4,38.1,123.3,28.4,108.8,28.4L108.8,28.4z M108.7,67.7
  c-9.5,0-15.9-6.6-15.9-15.7s6.4-15.7,15.9-15.7c9.6,0,15.9,6.6,15.9,15.7S118.2,67.7,108.7,67.7z"
      />

      <linearGradient
        id="SVGID_00000091733976289745658130000016050698779232148372_"
        gradientUnits="userSpaceOnUse"
        x1="-5.7982"
        y1="48.1156"
        x2="259.8031"
        y2="48.1156"
        gradientTransform="matrix(1 0 0 -1 0 109.1931)">
        <stop offset="0" style={{ stopColor: "#4EC9E8" }} />
        <stop offset="0.53" style={{ stopColor: "#B979FF" }} />
        <stop offset="1" style={{ stopColor: "#EB6DD3" }} />
      </linearGradient>
      <path
        style={{ fill: "url(#SVGID_00000091733976289745658130000016050698779232148372_)" }}
        d="M167.2,28.4c-14.3,0-24.6,9.4-24.6,24
  v41.3h8.9V66.6c3.4,5.8,9.7,9.1,17.2,9.1c12.1,0,23.5-8.5,23.5-23.4C192.2,38.2,181.9,28.4,167.2,28.4L167.2,28.4z M167.3,67.7
  c-9.5,0-15.8-6.6-15.8-15.7s6.4-15.7,15.8-15.7s15.9,6.6,15.9,15.7S176.8,67.7,167.3,67.7z"
      />

      <linearGradient
        id="SVGID_00000171719295682090364750000008228744341189749174_"
        gradientUnits="userSpaceOnUse"
        x1="-5.798"
        y1="66.957"
        x2="259.8031"
        y2="66.957"
        gradientTransform="matrix(1 0 0 -1 0 109.1931)">
        <stop offset="0" style={{ stopColor: "#4EC9E8" }} />
        <stop offset="0.53" style={{ stopColor: "#B979FF" }} />
        <stop offset="1" style={{ stopColor: "#EB6DD3" }} />
      </linearGradient>
      <path
        style={{ fill: "url(#SVGID_00000171719295682090364750000008228744341189749174_)" }}
        d="M225.3,28.4c-6.8,0-12.3,3-15.4,8.6v-27
  H201v64.6h8.9V51.2c0-9.6,5.7-14.7,14-14.7c8.2,0,14,5,14,14.7v23.4h8.9V51.2C246.7,35.4,236.8,28.4,225.3,28.4L225.3,28.4z"
      />

      <linearGradient
        id="SVGID_00000175293399147606970770000015441113259962391994_"
        gradientUnits="userSpaceOnUse"
        x1="-5.7981"
        y1="57.1849"
        x2="259.8031"
        y2="57.1849"
        gradientTransform="matrix(1 0 0 -1 0 109.1931)">
        <stop offset="0" style={{ stopColor: "#4EC9E8" }} />
        <stop offset="0.53" style={{ stopColor: "#B979FF" }} />
        <stop offset="1" style={{ stopColor: "#EB6DD3" }} />
      </linearGradient>
      <path
        style={{ fill: "url(#SVGID_00000175293399147606970770000015441113259962391994_)" }}
        d="M0,52c0,12.4,10.4,22.5,23.2,22.5
  C36,74.5,46.4,64.4,46.4,52c0-5.3-1.9-10.1-5-14h5.1v-8.6H23.2C10.4,29.5,0,39.6,0,52z M37.5,52c0,7.7-6.4,14-14.4,14
  c-7.9,0-14.4-6.3-14.4-14s6.4-14,14.4-14C31.1,38.1,37.5,44.3,37.5,52z"
      />

      <linearGradient
        id="SVGID_00000142878667309058568030000009111481996563867778_"
        gradientUnits="userSpaceOnUse"
        x1="260.1085"
        y1="66.8628"
        x2="260.2498"
        y2="66.8628"
        gradientTransform="matrix(1 0 0 -1 0 109.1931)">
        <stop offset="0" style={{ stopColor: "#25076C" }} />
        <stop offset="1" style={{ stopColor: "#130738" }} />
      </linearGradient>
      <path
        style={{ fill: "url(#SVGID_00000142878667309058568030000009111481996563867778_)" }}
        d="M260.1,42.4c0,0,0.1-0.1,0.1-0.1
  C260.2,42.3,260.1,42.4,260.1,42.4L260.1,42.4z"
      />
      <path
        className="st7"
        d="M297.7,54.5c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4-0.1-0.5c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.4-0.1-0.5
  c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.1-0.5-0.2-0.7c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.1-0.4-0.2-0.5c0-0.1-0.1-0.2-0.1-0.3
  c-0.1-0.2-0.2-0.4-0.2-0.6c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.3-0.6-0.4-0.9c0,0,0-0.1,0-0.1c-0.1-0.1-0.1-0.2-0.2-0.3
  c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.2-0.2-0.3-0.4-0.5c-0.1-0.2-0.2-0.3-0.4-0.5c-1.5-2-3.4-3.7-5.7-5.1v0
  c0.8-0.6,1.5-1.2,2.1-1.9c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.3-0.4,0.5-0.5c2-2.4,3.3-5.3,3.6-8.5c0,0,0,0,0-0.1
  c0-0.4,0.1-0.7,0.1-1.1c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0-9.4-8.6-17.1-19.1-17.1s-19.1,7.7-19.1,17.1c0,0.6,0,1.2,0.1,1.7
  c0,0.3,0.1,0.6,0.1,0.9c0,0.3,0.1,0.6,0.2,0.8c0.1,0.4,0.2,0.8,0.3,1.2c0.2,0.7,0.5,1.3,0.8,2c0.1,0.3,0.3,0.5,0.4,0.8
  c0.4,0.8,0.9,1.5,1.5,2.3c0.7,1,1.6,1.8,2.5,2.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.2c-0.3,0.2-0.5,0.3-0.8,0.5
  c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.4,0.3-0.6,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1,0.1-0.2,0.1-0.3,0.2
  c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1,0-0.1,0.1-0.1,0.1c-0.4,0.3-0.7,0.7-1.1,1.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.3-0.6,0.7-0.9,1.1
  c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.1,0.2-0.2,0.3
  c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.2-0.3,0.5-0.4,0.7c-0.2,0.3-0.3,0.6-0.4,0.9c0,0,0,0.1-0.1,0.1c-0.2,0.3-0.3,0.7-0.5,1.1
  c0,0,0,0.1-0.1,0.1c-0.1,0.2-0.2,0.5-0.3,0.7c0,0,0,0,0,0c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.3-0.2,0.7-0.3,1c0,0,0,0.1,0,0.1
  c0,0.2-0.1,0.4-0.1,0.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.2,0,0.3-0.1,0.5
  c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.3-0.1,0.7-0.1,1c0,0.2,0,0.4,0,0.6c0,0,0,0,0,0.1h0c0,0.1,0,0.2,0,0.3c0,10.8,9.8,19.7,21.9,19.7
  c12.1,0,21.9-8.8,21.9-19.7C297.8,55.5,297.8,55,297.7,54.5L297.7,54.5z M275.9,17.6c5.8,0,10.5,4.2,10.5,9.4c0,0.1,0,0.1,0,0.2
  c0,2.2-1,4.3-2.5,5.8c0,0,0,0-0.1,0.1c-0.2,0.2-0.4,0.4-0.6,0.6c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
  c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.3,0.1-0.4,0.2
  c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0.1-0.5,0.2-0.8,0.2c-0.1,0-0.3,0.1-0.4,0.1c0,0,0,0-0.1,0
  c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4,0.1c-0.2,0-0.5,0-0.7,0.1c-0.1,0-0.2,0-0.3,0h-0.5
  c-5.7-0.1-10.2-4.3-10.2-9.4S270.1,17.6,275.9,17.6L275.9,17.6z M275.9,68c-7.4,0-13.3-5.4-13.3-12c0-0.2,0-0.3,0-0.5
  c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.4c0-0.1,0-0.2,0.1-0.3
  c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3
  c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3
  c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3
  c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.2-0.2
  c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.4-0.3,0.6-0.4c0,0,0,0,0.1,0
  c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.3-0.2,0.5-0.2
  c0,0,0,0,0.1,0c0.2-0.1,0.4-0.1,0.5-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.6-0.2,1.2-0.4,1.9-0.5c0.4-0.1,0.8-0.1,1.3-0.2
  c0.4,0,0.8-0.1,1.2-0.1c0,0,0.1,0,0.1,0h0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c7.2,0.1,13,5.5,13,12C289.2,62.6,283.2,68,275.9,68
  L275.9,68z"
      />

      <linearGradient
        id="SVGID_00000128475577200240620100000002119054834397876096_"
        gradientUnits="userSpaceOnUse"
        x1="258.3442"
        y1="50.8018"
        x2="269.1102"
        y2="68.9138"
        gradientTransform="matrix(1 0 0 -1 0 109.1931)">
        <stop offset="0.19" style={{ stopColor: "#EB6DD3", stopOpacity: 0 }} />
        <stop offset="1" style={{ stopColor: "#130738" }} />
      </linearGradient>
      <path
        style={{ fill: "url(#SVGID_00000128475577200240620100000002119054834397876096_)" }}
        d="M275.6,44.1c-0.4,0-0.8,0-1.2,0.1
  c-0.4,0-0.9,0.1-1.3,0.2c-0.6,0.1-1.3,0.3-1.9,0.5c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.1-0.5,0.2c0,0,0,0-0.1,0
  c-0.2,0.1-0.4,0.2-0.5,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.2,0.1-0.3,0.2
  c0,0,0,0-0.1,0c-3.1,2.1-5.1,5.4-5.2,9.2h-8.6c0,0,0,0,0-0.1c0-0.2,0-0.4,0-0.6c0-0.3,0-0.7,0.1-1c0-0.2,0-0.4,0.1-0.6
  c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.1-0.5c0.1-0.6,0.2-1.1,0.4-1.7c0.1-0.2,0.1-0.4,0.2-0.6c0,0,0,0,0,0c0.1-0.2,0.2-0.5,0.3-0.7
  c0.2-0.5,0.4-0.9,0.6-1.4c0.1-0.3,0.3-0.6,0.4-0.9c0.1-0.2,0.3-0.5,0.4-0.7c0.4-0.7,0.9-1.4,1.4-2c0.7-0.9,1.4-1.7,2.3-2.5
  c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.3-0.3,0.5-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.3-0.3,0.5-0.4c0.1-0.1,0.2-0.2,0.3-0.2
  c0.2-0.2,0.4-0.3,0.6-0.4c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.5-0.4,0.8-0.5c0.2,0.2,0.5,0.4,0.7,0.5c0.1,0.1,0.2,0.2,0.4,0.3
  c0.2,0.2,0.5,0.3,0.7,0.5c0,0,0,0,0,0c0.2,0.2,0.5,0.3,0.8,0.4c0.8,0.4,1.6,0.8,2.5,1.1c0.1,0,0.2,0.1,0.3,0.1
  c0.5,0.2,0.9,0.3,1.4,0.5c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0.1,0.6,0.1
  c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0C273.5,44,274.5,44.1,275.6,44.1L275.6,44.1z"
      />

      <linearGradient
        id="SVGID_00000090278046198796973410000008915390554483672492_"
        gradientUnits="userSpaceOnUse"
        x1="290.2637"
        y1="84.447"
        x2="280.9211"
        y2="66.7281"
        gradientTransform="matrix(1 0 0 -1 0 109.1931)">
        <stop offset="0.19" style={{ stopColor: "#EB6DD3", stopOpacity: 0 }} />
        <stop offset="1" style={{ stopColor: "#130738" }} />
      </linearGradient>
      <path
        style={{ fill: "url(#SVGID_00000090278046198796973410000008915390554483672492_)" }}
        d="M294.9,27.2c0,3.7-1.4,7.1-3.7,9.9
  c-0.2,0.2-0.3,0.4-0.5,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.6,0.7-1.4,1.3-2.1,1.9c-0.7-0.4-1.4-0.8-2.2-1.2c-0.3-0.1-0.5-0.2-0.8-0.4
  c-0.3-0.1-0.6-0.3-1-0.4c-0.5-0.2-1-0.4-1.5-0.5c-0.3-0.1-0.5-0.2-0.8-0.2c0,0,0,0,0,0c-0.2-0.1-0.5-0.1-0.7-0.2
  c-0.6-0.1-1.2-0.3-1.9-0.4c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.5,0-0.7-0.1c-0.4,0-0.7-0.1-1.1-0.1
  c-0.2,0-0.4,0-0.6,0h0c0.3,0,0.6,0,1-0.1c0.2,0,0.3,0,0.4-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4-0.1c0,0,0,0,0.1,0
  c0.1,0,0.3-0.1,0.4-0.1c0.3-0.1,0.6-0.1,0.8-0.2c0.1,0,0.3-0.1,0.4-0.1c0.2-0.1,0.3-0.1,0.5-0.2c0.1-0.1,0.3-0.1,0.4-0.2
  c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.3
  c0.1-0.1,0.3-0.2,0.4-0.3c1.9-1.7,3.1-4,3.1-6.5L294.9,27.2L294.9,27.2z"
      />
      <path
        className="st7"
        d="M418.8,28.5h-99.1c-6.8,0-12.2,4.7-12.2,10.6v26.1c0,5.9,5.5,10.6,12.2,10.6h99.1c6.8,0,12.2-4.7,12.2-10.6
  V39.1C431.1,33.2,425.6,28.5,418.8,28.5z"
      />
      <g>
        <path
          className="st10"
          d="M316.4,52.4c0-6.6,4.6-11.2,11.3-11.2c6.5,0,11.1,4.4,11.1,11.1v10.3h-4.6v-4.1c-1.3,3.1-4.1,4.6-7.4,4.6
    C321.7,63.2,316.4,59.5,316.4,52.4z M333.7,52.2c0-3.7-2.4-6.3-6.1-6.3c-3.7,0-6.2,2.6-6.2,6.3c0,3.7,2.5,6.3,6.2,6.3
    C331.3,58.5,333.7,55.8,333.7,52.2z"
        />
        <path className="st10" d="M342.6,34.1h5v28.5h-5V34.1z" />
        <path
          className="st10"
          d="M351.5,52.3c0-6.8,4.6-11.1,11.1-11.1c6.6,0,11.3,4.6,11.3,11.2c0,7.1-5.3,10.8-10.4,10.8
    c-3,0-5.5-1.3-7-3.6v10.6h-5V52.3z M368.8,52.2c0-3.7-2.5-6.3-6.2-6.3c-3.7,0-6.1,2.6-6.1,6.3c0,3.7,2.5,6.3,6.1,6.3
    C366.3,58.5,368.8,55.8,368.8,52.2z"
        />
        <path
          className="st10"
          d="M376.8,34.1h5v10.6c1.4-2.2,3.5-3.5,6.2-3.5c4.8,0,9.4,3.1,9.4,10.6v10.8h-5V51.8c0-4-2.1-5.9-5.3-5.9
    c-3.1,0-5.3,2-5.3,5.9v10.8h-5V34.1z"
        />
        <path
          className="st10"
          d="M399.9,52.4c0-6.6,4.6-11.2,11.3-11.2c6.5,0,11.1,4.4,11.1,11.1v10.3h-4.6v-4.1c-1.3,3.1-4.1,4.6-7.4,4.6
    C405.2,63.2,399.9,59.5,399.9,52.4z M417.2,52.2c0-3.7-2.5-6.3-6.1-6.3c-3.7,0-6.2,2.6-6.2,6.3c0,3.7,2.5,6.3,6.2,6.3
    C414.8,58.5,417.2,55.8,417.2,52.2z"
        />
      </g>
    </g>
  </svg>
  );
}

export default function Graph8Logo(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
