import React from "react";

import type { IconProps } from "./types";

const AiPromptTemplatesIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 27"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    className="tabler-icon tabler-icon-prompt">
    <path d="M5 7l5 5l-5 5" stroke={stroke} />
    <path d="M13 17l6 0" stroke={stroke} />
  </svg>
);

export default AiPromptTemplatesIcon;
