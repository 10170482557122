import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} style={style}>
      <path d="M2.66921 7.36413C3.63354 3.76522 7.33278 1.62946 10.9317 2.59379C13.0025 3.14865 14.5888 4.60897 15.379 6.43464" stroke={stroke} stroke-linecap="round"/>
      <path d="M15.3308 10.6359C14.3665 14.2348 10.6673 16.3705 7.06836 15.4062C4.99758 14.8514 3.41122 13.391 2.62109 11.5654" stroke={stroke} stroke-linecap="round"/>
      <path d="M11.4354 6.1004L15.5618 6.85024L16.3117 2.72376" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.5647 11.8996L2.43821 11.1498L1.68838 15.2762" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default function ContactSupportIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
