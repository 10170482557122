import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg 
      width="100%" 
      height="100%" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path d="M17 8L10.125 15L7 11.8182" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default function CheckIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
