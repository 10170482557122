import React from "react";

import type { IconProps } from "./types";

const WebChatsIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.1422 20.2845C23.6929 19.6017 23.9954 18.7522 24 17.8751C24 15.406 21.5905 13.4108 18.6039 13.4108C15.6322 13.4108 13.2078 15.4067 13.2078 17.8751C13.2078 20.3434 15.6172 22.3394 18.6039 22.3394C19.5129 22.3446 20.4118 22.1476 21.2354 21.7627L23.7044 22.4868L23.1422 20.2845Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.7802 13.2138C19.7802 10.0614 16.6841 7.51305 12.8901 7.51305C9.07973 7.51305 6 10.0614 6 13.2138C6.00505 14.3349 6.39116 15.4209 7.09494 16.2936L6.37808 19.1034L9.53056 18.1781C10.5822 18.6696 11.7299 18.9212 12.8907 18.9147"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default WebChatsIcon;
