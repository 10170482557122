import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="  {className}" style={style}>
      <path d="M9.9738 4.37503C9.26236 4.37503 8.56691 4.58596 7.97537 4.98122C7.38383 5.37647 6.9228 5.93827 6.65054 6.59556C6.37829 7.25284 6.30703 7.97612 6.44582 8.67388C6.58462 9.37165 6.92724 10.0126 7.4303 10.5157C7.93337 11.0187 8.57431 11.3613 9.27208 11.5001C9.96985 11.6389 10.6931 11.5677 11.3504 11.2954C12.0076 11.0232 12.5694 10.5621 12.9647 9.97055C13.36 9.37901 13.5709 8.68355 13.5709 7.97212C13.5729 7.49919 13.4812 7.03055 13.3011 6.59324C13.121 6.15594 12.8562 5.75863 12.5217 5.42422C12.1873 5.0898 11.79 4.8249 11.3527 4.64482C10.9154 4.46475 10.4467 4.37307 9.9738 4.37503V4.37503Z" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <circle cx="10" cy="10" r="9" stroke={stroke}/>
      <path d="M4.58813 17.1918C4.88191 16.2118 5.43858 15.3229 6.20691 14.6269C7.24599 13.6856 8.59794 13.1643 9.99997 13.1643C11.402 13.1643 12.7539 13.6856 13.793 14.6269C14.5613 15.3229 15.118 16.2117 15.4118 17.1917" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default function AccountIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
