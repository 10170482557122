import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} style={style}>
      <path d="M17.0727 19.0336H2.92726C2.6738 19.0339 2.4228 18.9841 2.18863 18.8871C1.95446 18.7901 1.74175 18.6478 1.56269 18.4684C1.38363 18.289 1.24175 18.0761 1.14519 17.8417C1.04864 17.6074 0.999297 17.3563 1.00001 17.1028V4.91066C0.999771 4.6575 1.04947 4.40681 1.14624 4.17287C1.24301 3.93894 1.38497 3.72635 1.56398 3.54734C1.74299 3.36833 1.95552 3.2264 2.18945 3.12963C2.42339 3.03286 2.67411 2.98316 2.92726 2.9834H17.0727C17.3259 2.98316 17.5766 3.03286 17.8105 3.12963C18.0445 3.2264 18.257 3.36833 18.436 3.54734C18.6151 3.72635 18.757 3.93894 18.8538 4.17287C18.9505 4.40681 19.0002 4.6575 19 4.91066V17.1028C19.0007 17.3563 18.9514 17.6074 18.8548 17.8417C18.7582 18.0761 18.6164 18.289 18.4373 18.4684C18.2583 18.6478 18.0455 18.7901 17.8114 18.8871C17.5772 18.9841 17.3262 19.0339 17.0727 19.0336V19.0336Z" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1.09045 7.97119H18.9996" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 13.4187L9.5857 14.8373L12.7236 12" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.16144 0.966309V5.13737" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.0657 0.966309V5.13737" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default function ChangeThemeIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
