import React from "react";

import type { IconProps } from "./types";

const ModelsIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.01489 17.8493V11.8362L11.7857 10.3491"
      stroke={stroke}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.0496 17.8492V11.8361L19.5831 10.8634L18.3499 10.3771"
      stroke={stroke}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.8462 22.1484L15.032 23.3697L18.1648 22.1484"
      stroke={stroke}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M15.032 14.4762L15.032 23.3694" stroke={stroke} stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M8.01489 11.8361L15.0322 14.4762L22.0495 11.9055"
      stroke={stroke}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="15.0321" cy="7.38954" r="1.11165" stroke={stroke} stroke-linejoin="round" />
    <path d="M15.032 11.6276V8.57056" stroke={stroke} stroke-linecap="round" stroke-linejoin="round" />
    <circle
      cx="22.4279"
      cy="22.15"
      r="1.11165"
      transform="rotate(135 22.4279 22.15)"
      stroke={stroke}
      stroke-linejoin="round"
    />
    <path
      d="M19.4311 19.153L21.5928 21.3147"
      stroke={stroke}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="1.11165"
      cy="1.11165"
      r="1.11165"
      transform="matrix(0.707107 0.707107 0.707107 -0.707107 6 22.15)"
      stroke={stroke}
      stroke-linejoin="round"
    />
    <path
      d="M10.5689 19.153L8.40723 21.3147"
      stroke={stroke}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ModelsIcon;
