import React from "react";

import type { IconProps } from "./types";

const ReportsIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="7"
      y="23.0966"
      width="10.6667"
      height="16"
      rx="2"
      transform="rotate(-90 7 23.0966)"
      stroke={stroke}
    />
    <path d="M10.8484 16.1534H14.8484" stroke={stroke} stroke-linecap="round" />
    <path d="M10.8484 18.8424H18.8484" stroke={stroke} stroke-linecap="round" />
    <path
      d="M21.5454 12.2367L21.5454 11.57C21.5454 10.4655 20.65 9.57004 19.5454 9.57004L10.2121 9.57004C9.10751 9.57004 8.21208 10.4655 8.21208 11.57L8.21208 12.2367"
      stroke={stroke}
      stroke-linecap="round"
    />
    <path
      d="M20.3334 9.57005L20.3334 8.90338C20.3334 7.79881 19.4379 6.90338 18.3334 6.90338L11.6667 6.90338C10.5621 6.90338 9.66671 7.79881 9.66671 8.90338L9.66671 9.57005"
      stroke={stroke}
      stroke-linecap="round"
    />
  </svg>
);

export default ReportsIcon;
