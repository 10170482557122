import ChatSnippetTemplate from "./ChatSnippetTemplate";

export default async function ChatSnippet(email: string, name: string) {
    try {
        const response = await fetch(`/api/uui/chat-jwe-data`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email, name: name }),
        });

        if (response.status !== 200) {
            console.error(`Error while fetching jwe_data: Received status code ${response.status}`);
            return "YOUR_ENCRYPTED_JWE_DATA";
        }

        const { jwe_data } = await response.json();
        await ChatSnippetTemplate(jwe_data);
    } catch (error) {
        console.error("Error while fetching jwe_data:", error);
        return "YOUR_ENCRYPTED_JWE_DATA";
    }
}
