import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} className={className}>
      <path d="M11.4037 13.1033L12.9894 14.522L16.1272 11.6846" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.4285 18.5715C16.5056 18.5715 19 16.0771 19 13.0001C19 9.92306 16.5056 7.42865 13.4285 7.42865C10.3515 7.42865 7.85712 9.92306 7.85712 13.0001C7.85712 16.0771 10.3515 18.5715 13.4285 18.5715Z" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.0926 7.67231C16.0926 4.21961 12.7016 1.42859 8.54627 1.42859C4.37303 1.42859 1 4.21961 1 7.67231C1.00553 8.90015 1.42842 10.0896 2.19922 11.0454L1.41409 14.1227L4.8668 13.1094C5.83594 13.5623 6.8796 13.8292 7.94361 13.8982" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default function LiveChatIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
