import React from "react";

import type { IconProps } from "./types";

const VisitorsIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8473 7.00003C13.1142 7.00003 12.3976 7.21738 11.7881 7.62466C11.1786 8.03194 10.7035 8.61082 10.423 9.2881C10.1424 9.96538 10.069 10.7107 10.212 11.4296C10.355 12.1486 10.7081 12.8091 11.2265 13.3275C11.7448 13.8458 12.4053 14.1988 13.1243 14.3418C13.8432 14.4848 14.5885 14.4115 15.2658 14.1309C15.943 13.8504 16.5219 13.3753 16.9292 12.7658C17.3365 12.1562 17.5539 11.4396 17.5539 10.7065C17.5559 10.2192 17.4614 9.73633 17.2758 9.28572C17.0903 8.83511 16.8174 8.42572 16.4728 8.08113C16.1282 7.73655 15.7187 7.46358 15.2681 7.27803C14.8175 7.09248 14.3346 6.99801 13.8473 7.00003V7.00003Z"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.7821 17.5637C16.7114 16.5938 15.3183 16.0566 13.8736 16.0566C12.429 16.0566 11.0359 16.5938 9.96521 17.5637C8.89453 18.5336 8.22274 19.867 8.0804 21.3046C8.07255 21.3812 8.08092 21.4587 8.10498 21.5318C8.12903 21.6049 8.16823 21.6722 8.22001 21.7292C8.27179 21.7861 8.33498 21.8316 8.40549 21.8625C8.47601 21.8934 8.55225 21.9091 8.62925 21.9086H12.3877"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.1306 20.8776L17.4106 23L21.9224 18.7551"
      stroke={stroke}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default VisitorsIcon;
