import React from "react";

import type { IconProps } from "./types";

const CampaignsIcon: React.FC<IconProps> = ({ size = 30, stroke = "currentColor" }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.2942 23V18.2942" stroke={stroke} stroke-linecap="round" />
    <path
      d="M8.0354 11.7647C8.0354 11.2124 8.48312 10.7647 9.0354 10.7647H12.553V18.294H9.0354C8.48312 18.294 8.0354 17.8463 8.0354 17.294V11.7647Z"
      stroke={stroke}
    />
    <path
      d="M12.553 10.7647L20.5933 7.54856C21.2501 7.28581 21.9647 7.76957 21.9647 8.47703V20.5816C21.9647 21.2891 21.2501 21.7729 20.5933 21.5101L12.553 18.294V10.7647Z"
      stroke={stroke}
    />
  </svg>
);

export default CampaignsIcon;
