import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)"

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none" xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path d="M8.89081 6.72332C11.9132 4.97832 15.778 6.01388 17.523 9.03631C18.5271 10.7754 18.6105 12.7933 17.9247 14.5259" stroke={stroke} stroke-linecap="round"/>
      <path d="M15.1091 17.2767C12.0866 19.0217 8.22187 17.9861 6.47687 14.9637C5.47282 13.2246 5.38933 11.2067 6.07522 9.47412" stroke={stroke} stroke-linecap="round"/>
      <path d="M15.5332 11.6926L17.7697 14.9224L20.9995 12.6859" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.46667 12.3077L6.23018 9.07789L3.00039 11.3144" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default function SyncsIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
