import React, { useEffect, useState } from "react";

import AiConversationsIcon from "../UuiNavigation/icons/AiConversationsIcon";
import AiPromptTemplatesIcon from "../UuiNavigation/icons/AiPromptTemplatesIcon";
import AiTemplatesIcon from "../UuiNavigation/icons/AiTemplatesIcon";
import AppointmentsIcon from "../UuiNavigation/icons/AppointmentsIcon";
import ArticlesIcon from "../UuiNavigation/icons/ArticlesIcon";
import BotTasksIcon from "../UuiNavigation/icons/BotTasksIcon";
import CampaignsIcon from "../UuiNavigation/icons/CampaignsIcon";
import ConnectionsIcon from "../UuiNavigation/icons/ConnectionsIcon";
import ContactsIcon from "../UuiNavigation/icons/ContactsIcon";
import GoEngageCampaignIcon from "../UuiNavigation/icons/GoEngageCampaignIcon";
import HomeIcon from "../UuiNavigation/icons/HomeIcon";
import IntentsIcon from "../UuiNavigation/icons/IntentsIcon";
import ListsIcon from "../UuiNavigation/icons/ListsIcon";
import ModelsIcon from "../UuiNavigation/icons/ModelsIcon";
import ReportsIcon from "../UuiNavigation/icons/ReportsIcon";
import SearchIcon from "../UuiNavigation/icons/SearchIcon";
import SettingsIcon from "../UuiNavigation/icons/SettingsIcon";
import VisitorsIcon from "../UuiNavigation/icons/VisitorsIcon";
import WebChatsIcon from "../UuiNavigation/icons/WebChatsIcon";
import { menuItems } from "./NavItems";

const iconMapping: { [key: string]: JSX.Element } = {
  search: <SearchIcon stroke="#9ca3af" />,
  contacts: <ContactsIcon stroke="#9ca3af" />,
  lists: <ListsIcon stroke="#9ca3af" />,
  intents: <IntentsIcon stroke="#9ca3af" />,
  webchats: <WebChatsIcon stroke="#9ca3af" />,
  campaigns: <CampaignsIcon stroke="#9ca3af" />,
  engage_campaign: <GoEngageCampaignIcon stroke="#9ca3af" />,
  bot_tasks: <BotTasksIcon stroke="#9ca3af" />,
  appointments: <AppointmentsIcon stroke="#9ca3af" />,
  visitors: <VisitorsIcon stroke="#9ca3af" />,
  connections: <ConnectionsIcon stroke="#9ca3af" />,
  models: <ModelsIcon stroke="#9ca3af" />,
  ai_conversations: <AiConversationsIcon stroke="#9ca3af" />,
  ai_templates: <AiTemplatesIcon stroke="#9ca3af" />,
  ai_prompt_templates: <AiPromptTemplatesIcon stroke="#9ca3af" />,
  articles: <ArticlesIcon stroke="#9ca3af" />,
  reports: <ReportsIcon stroke="#9ca3af" />,
  settings: <SettingsIcon stroke="#9ca3af" />,
  home: <HomeIcon stroke="#9ca3af" />,
};

export const getCookie = (name: any) : string| undefined =>  {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
  return undefined;
}

export default function UuiNavigation() {
  const uuiBaseURL = "https://uui.cience.com";
  const [uuiAppId, setUuiAppId] = useState<string | null>(null);
  const [uuiAiType, setUuiAiType] = useState<string | null>(null);

  useEffect(() => {
    const appId = getCookie("uuiAppId");
    const uuiAiType = getCookie("aiType");
    setUuiAppId(appId ?? null);
    setUuiAiType(uuiAiType ?? null);
  }, []);

  let loginUrl: string | undefined = undefined;

  if (!uuiAppId) {
    loginUrl = `${uuiBaseURL}/agents/sign_in`;
  }
  const uuiAppPath = `/apps/${uuiAppId}`;
  const aiTypeString = uuiAiType ?? "0";

  return (
    <div className="border-subtle border-r bg-default sticky top-0 hidden max-h-[960px] w-[185px] overflow-y-auto px-3 pt-2 md:block">
      <a
        key="home"
        data-test-id="Home"
        aria-label="Dashboard"
        className="text-black dark:text-white dark:hover:bg-[--brand-default] hover:bg-[#DDC5FF] group my-5 flex h-10 cursor-pointer items-center rounded-[10px] text-sm transition"
        href={uuiAppId ? uuiBaseURL + uuiAppPath : loginUrl}

        data-state="closed">
        <div>{iconMapping["home"]}</div>
        <span
          className="ml-2 w-full justify-between truncate text-ellipsis lg:flex"
          data-testid="bookings-test">
          Home
        </span>
      </a>
      {menuItems(aiTypeString)
        .filter((item) => !item.hidden)
        .map((item, index) => {
          if (item.type === "separator") {
            return (
              <div key={index} className="h-5">
                <span className="nav-item text-gray-600 pl-1 text-sm  uppercase tracking-wider">
                  {item.label}
                </span>
              </div>
            );
          } else if (item.type === "link") {
            const href =
              item.name === "Go Engage" || item.name === "Appointments"
                ? uuiBaseURL + "/auth/" + uuiAppId + item.path
                : uuiAppId
                ? uuiBaseURL + uuiAppPath + item.path
                : loginUrl;

            if (item.name === "Connections") {
              return (
                <div
                  key={index}
                  data-test-id={item.name}
                  aria-label={item.tip}
                  className="text-primary dark:!text-white todesktop:hover:bg-transparent bg-[#DDC5FF] dark:bg-[--brand-default] group my-2 flex h-10 cursor-pointer items-center rounded-[10px] text-sm  transition"
                  data-state="closed">
                  <div>{iconMapping[item.icon]}</div>
                  <span
                    className="ml-2 w-full justify-between truncate text-ellipsis lg:flex"
                    data-testid="bookings-test">
                    {item.name}
                  </span>
                </div>
              );
            }

            return (
              <a
                key={index}
                data-test-id={item.name}
                aria-label={item.tip}
                className="hover:bg-[#D6CFFF] dark:hover:bg-[--brand-default] dark:hover:text-white group my-2 flex h-10 items-center rounded-[10px] text-sm transition dark:bg-[#0a0a0a] text-black dark:text-white"
                data-state="closed"
                href={href}>
                <div>{iconMapping[item.icon]}</div>
                <div
                  className="ml-2 w-full justify-between truncate text-ellipsis lg:flex"
                  data-testid="bookings-test">
                  {item.name}
                </div>
              </a>
            );
          }
          return null;
        })}
    </div>
  );
}
