import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg 
      width="100%" 
      height="100%" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path d="M12 19.4672C16.124 19.4672 19.4671 16.124 19.4671 12C19.4671 7.87605 16.124 4.5329 12 4.5329C7.87599 4.5329 4.53284 7.87605 4.53284 12C4.53284 16.124 7.87599 19.4672 12 19.4672Z" stroke={stroke} stroke-miterlimit="10"/>
      <path d="M12 19.4672C13.9072 19.4672 15.4532 16.124 15.4532 12C15.4532 7.87605 13.9072 4.5329 12 4.5329C10.0929 4.5329 8.54688 7.87605 8.54688 12C8.54688 16.124 10.0929 19.4672 12 19.4672Z" stroke={stroke} stroke-miterlimit="10"/>
      <path d="M4.53284 12H19.4671" stroke={stroke} stroke-miterlimit="10"/>
    </svg>
  );
}

export default function WebIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
